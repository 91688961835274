<template>
  <div class="wrapper flex flex-col teext-indigo-200 justify-between items-stretch">
    <component
      :is="element.component"
      v-for="(element, index) of schema"
      :key="index"
      :content="element.content"
    />
  </div>
</template>

<script>
const LogoBlock = ({ props }) => (
  <img
    class="md:h-32 w-auto inline-block ml-4 mr-6 mb-4"
    src={props.content}
    aria-hidden="true"
  />
)

const RowBlock = {
  functional: true,
  render(h, { props }) {
    return (
      <div class="flex flex-col md:flex-row flex-grow">
        {props.content.map((el) => (
          <el.component content={el.content} />
        ))}
      </div>
    )
  },
  props: { content: Array },
}

const NavBlock = {
  functional: true,
  render(h, { props }) {
    return (
      <div class="block mb-8 px-4 text-left w-full">
        <h2 class="text-xs foot-headers mb-2 md:h-4 block uppercase">
          {props.content.header}
        </h2>
        <ul>
          {props.content.links.map((link) => (
            <li>
              <a
                class="hover:underline text-sm mb-2 inline-block"
                href={link.url}
              >
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  },
  props: { content: Object },
}

const FreeFormBlock = {
  functional: true,
  render(h, { props }) {
    return h('div', {
      class: 'text-left block w-full px-4',
      domProps: {
        innerHTML: props.content,
      },
    })
  },
  props: { content: String },
}

const NewBlock = () => <div />

export default {
  components: {
    LogoBlock,
    FreeFormBlock,
    NavBlock,
    RowBlock,
    NewBlock,
  },
  props: {
    schema: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.wrapper >>> p {
  @apply text-sm;
  color: var(--footerElementsFontColor, var(--text-indigo-200));
}

.wrapper >>> a {
  @apply text-sm;
  color: var(--footerElementsFontColor, var(--text-indigo-200));
}

.wrapper >>> a:hover {
  text-decoration: underline;
}
.foot-headers {
  color: var(--footerElementsFontColor, var(--text-indigo-400));
  font-weight: var(--footerElementsFontWeight, 600);
}
</style>
