<template>
  <a
    v-if="link.linkType === 'external'"
    :key="link.url"
    :href="link.url"
    :title="link.label"
    rel="noopener"
    target="_blank"
  >
    <slot/>
  </a>
  <NuxtLink
    v-else
    :key="correctedLink.url"
    :to="correctedLink.url"
    :title="correctedLink.label"
  >
    <slot/>
  </NuxtLink>
</template>
<script>
import { prebuildUrlCorrector } from '@/utils/url'
export default {
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  computed: {
    correctedLink() {
      const link = this.link
      return link.pageType === 'pre-defined' ? prebuildUrlCorrector(link) : link
    },
  },
}
</script>
