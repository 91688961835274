import { render, staticRenderFns } from "./demo.vue?vue&type=template&id=4b434a60&scoped=true"
import script from "./demo.vue?vue&type=script&lang=js"
export * from "./demo.vue?vue&type=script&lang=js"
import style0 from "./demo.vue?vue&type=style&index=0&id=4b434a60&prod&media=screen&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b434a60",
  null
  
)

export default component.exports