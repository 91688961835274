<template>
  <div class="pr-4">
    <div class="mb-1">
      <p class="text-sm leading-6 mb-2 uppercase sub-link">
        {{ list.label }}
      </p>
    </div>
    <div
      v-for="(link, index) in list.children"
      :key="index"
    >
      <NavLink
        v-if="link.type === 'link'"
        :link="link"
        class="-m-2 p-2 flex items-start space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
      >
        <div class="space-y-1">
          <p class="text-sm leading-6 item-link">
            {{ link.label }}
          </p>
        </div>
      </NavLink>
    </div>
  </div>
</template>
<script>
import NavLink from './NavLink'
export default {
  name: 'NavSubGroup',
  components: { NavLink },
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.sub-link {
  color: var(--interfaceFontColor, var(--text-gray-400));
  font-family: var(--sidebarElementsFontTypeface, inherit);
  font-weight: var(--sidebarElementsFontWeight, 700);
}

.item-link {
  color: var(--interfaceFontColor, var(--text-gray-600));
  font-family: var(--sidebarElementsFontTypeface, inherit);
  font-weight: var(--sidebarElementsFontWeight, 500);
}
</style>
