import { render, staticRenderFns } from "./richmond.vue?vue&type=template&id=30ffdab7"
import script from "./richmond.vue?vue&type=script&lang=js"
export * from "./richmond.vue?vue&type=script&lang=js"
import style0 from "./richmond.vue?vue&type=style&index=0&id=30ffdab7&prod&media=screen&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports