<template>
  <div
    :style="{
      ...themeVariables,
      ...fontsConfig,
    }"
    class="min-h-screen flex flex-col theme-container"
  >
    <SkipToMain />
    <div
      v-if="customBeforePageHtml"
      v-html="customBeforePageHtml"
    />
    <NavBar>
      <a href="/">Caspersen School of Graduate Studies</a>
    </NavBar>
    <PageBreadcrumbs
      class="pt-4 px-4 max-w-screen-xl mx-auto w-full"
      :path="path"
    />
    <span
      class="visually-hidden"
      role="status"
    >
      <template v-if="hasAlert">Navigation complete. Page has loaded.</template>
    </span>
    <div
      id="main-content" role="main"
      class="flex-grow"
    >
      <nuxt />
    </div>
    <FooterBar class="flex-no-shrink" />
    <div
      v-if="customAfterPageHtml"
      v-html="customAfterPageHtml"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FooterBar from '~contemporaryTheme/components/FooterBar'
import baseLayoutMixin from '@/mixins/baseLayoutMixin'
import NavBar from '~contemporaryTheme/components/NavBar'
import SkipToMain from '@/components/SkipToMain'
import PageBreadcrumbs from '@/components/PageBreadcrumbs'
import { pagePath } from '@/utils/navigation'

export default {
  components: { NavBar, FooterBar, SkipToMain, PageBreadcrumbs },
  extends: baseLayoutMixin,
  data() {
    return {
      hasAlert: false,
    }
  },
  computed: {
    ...mapGetters('settings', [
      'layout',
      'customBeforePageHtml',
      'customAfterPageHtml',
    ]),
    ...mapGetters('page', ['currentPageLabel']),
    ...mapState('settings', ['sidebar']),
    path() {
      return pagePath(this.$route, this.sidebar, this.currentPageLabel)
    },
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (newRoute.path === oldRoute.path) return
      this.announcePage()
    },
  },
  mounted() {
    import(`~contemporaryTheme/components/DefaultWrapper.vue`)
  },
  methods: {
    announcePage() {
      this.hasAlert = true
      setTimeout(() => {
        this.hasAlert = false
      }, 1300)
    },
  },
}
</script>

<style media="screen" lang="scss">
@import '@/assets/css/contemporaryTheme/main.scss';
</style>
