<template>
  <footer
    class="bg-indigo-900 pb-7 pt-8"
  >
    <div class="max-w-screen-xl px-4 mx-auto flex flex-col">
      <SchemaLayout v-if="footerSchema" :schema="footerSchema"/>
      <div v-if="!hideDownloadCatalogPdf" class="flex pt-4 mx-4 mt-4">
        <div class="flex-col w-1/2">
          <RecentPdfButton />
        </div>
        <div class="flex-col w-1/2 self-center">
          <CoursedogLink/>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SchemaLayout from './SchemaLayout'
import RecentPdfButton from '@/components/RecentPdfButton.vue'
import CoursedogLink from '@/components/CoursedogLink'

export default {
  components: { CoursedogLink, SchemaLayout, RecentPdfButton },
  computed: {
    ...mapState('settings', ['schoolDisplayName', 'settings']),
    ...mapGetters('settings', ['hideDownloadCatalogPdf']),
    footerSchema() {
      return this.settings.footer
    },
    logo() {
      return this.settings.footerImage
    },
  },
}
</script>

<style scoped>
.link {
  cursor: pointer;
}
footer {
  color: var(--footerElementsFontColor, white);
  font-family: var(--footerElementsFontTypeface, inherit);
  font-weight: var(--footerElementsFontWeight, inherit);
}
</style>
