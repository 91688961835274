<template>
  <div
    :style="{
      ...themeVariables,
      ...fontsConfig,
    }"
    class="min-h-screen flex flex-col theme-container"
  >
    <div
      v-if="customBeforePageHtml"
      v-html="customBeforePageHtml"
    />
    <NavBar />
    <PageBreadcrumbs
      class="pt-6 px-4 max-w-screen-xl mx-auto w-full hidden md:flex"
      :path="path"
    />
    <span
      class="visually-hidden"
      role="status"
    >
      <template v-if="hasAlert">Navigation complete. Page has loaded.</template>
    </span>
    <div class="flex-grow">
      <nuxt />
    </div>
    <FooterBar class="flex-no-shrink" />
    <div
      v-if="customAfterPageHtml"
      v-html="customAfterPageHtml"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FooterBar from '@/themes/demo/FooterBar'
import baseLayoutMixin from '@/mixins/baseLayoutMixin'
import NavBar from '@/themes/demo/NavBar'
import PageBreadcrumbs from '@/components/PageBreadcrumbs'
import { pagePath } from '@/utils/navigation'

export default {
  components: { NavBar, FooterBar, PageBreadcrumbs },
  extends: baseLayoutMixin,
  data() {
    return {
      hasAlert: false,
    }
  },
  computed: {
    ...mapGetters('settings', [
      'layout',
      'customBeforePageHtml',
      'customAfterPageHtml',
    ]),
    ...mapGetters('page', ['currentPageLabel']),
    ...mapState('settings', ['sidebar']),
    path() {
      return pagePath(this.$route, this.sidebar, this.currentPageLabel)
    },
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (newRoute.path === oldRoute.path) return
      // this.$refs.content.focus()
      this.announcePage()
    },
  },
  mounted() {
    import(`@/themes/demo/DefaultWrapper.vue`)
  },
  methods: {
    announcePage() {
      this.hasAlert = true
      setTimeout(() => {
        this.hasAlert = false
      }, 1300)
    },
  },
}
</script>

<style media="screen" scoped>
:root {
  --text-gray-400: rgba(159, 166, 178, 1);
  --text-gray-500: rgba(107, 114, 128, 1);
  --text-gray-600: rgba(75, 85, 99, 1);
  --text-gray-700: rgba(55, 65, 81, 1);
  --text-gray-800: rgba(37, 47, 63, 1);
  --text-gray-900: rgba(22, 30, 46, 1);
  --text-blue-600: rgba(28, 100, 242, 1);
  --text-indigo-200: rgba(205, 219, 254, 1);
  --text-indigo-400: rgba(141, 162, 251, 1);
  --text-indigo-600: rgba(88, 80, 236, 1);
  --text-theme-500: var(--theme, #00573d);
  --text-theme-600: var(--theme-dark, #003525);
}

.ui-font {
  font-family: var(--interfaceFontTypeface, inherit);
}

.theme-container {
  font-family: var(--baseFontFace, inherit);
}

.layout {
  flex: 1 0 auto;
}

.department-link {
  @apply rounded-full bg-theme-500 uppercase px-3 py-1 text-xs font-bold text-white inline-flex flex-grow-0 flex;
  color: white !important;
}

.department-link:hover {
  @apply bg-theme-400;
}

.theme-page-content {
  @apply text-base leading-normal w-full relative;
}

.theme-page-content hr {
  @apply border-b my-4 border-gray-400;
}
.theme-page-content h1 {
  @apply text-5xl mb-3 leading-tight;
  color: var(--h1HeaderFontColor, var(--text-gray-800));
  font-family: var(--h1HeaderFontTypeface, inherit);
  font-weight: var(--h1HeaderFontWeight, 700);
}
.theme-page-content h2 {
  @apply text-3xl mb-3;
  color: var(--h2HeaderFontColor, var(--text-gray-800));
  font-family: var(--h2HeaderFontTypeface, inherit);
  font-weight: var(--h2HeaderFontWeight, 700);
}
.theme-page-content h3 {
  @apply text-2xl mb-4;
  color: var(--h3HeaderFontColor, var(--text-gray-800));
  font-family: var(--h3HeaderFontTypeface, inherit);
  font-weight: var(--h3HeaderFontWeight, 600);
}
.theme-page-content h4 {
  @apply text-xl text-gray-800 mb-3 mt-6;
}
.theme-page-content h5 {
  @apply text-lg text-gray-800 mb-3 mt-6;
}
.theme-page-content p {
  @apply leading-relaxed text-lg mb-3 break-words;
  min-height: 1.625rem;
  color: var(--textFontColor, var(--text-gray-700));
  font-family: var(--textFontTypeface, inherit);
  font-weight: var(--textFontWeight, inherit);
}
.theme-page-content p:empty:before {
  content: ' ';
  white-space: pre;
}
.theme-page-content img {
  @apply rounded;
}
.theme-page-content ul {
  @apply leading-relaxed mb-3 text-gray-700;
}
.theme-page-content ol {
  @apply leading-relaxed mb-3 text-gray-700;
}
.theme-page-content em {
  @apply font-semibold border-dotted border-b;
}
.theme-page-content a {
  @apply font-semibold text-theme-400;
}
.theme-page-content a:hover {
  @apply text-theme-500 underline;
}
.theme-page-content code {
  @apply py-1 px-2 bg-gray-200;
}
.theme-page-content blockquote {
  @apply pl-4 py-2 border-l-2 leading-relaxed text-lg italic text-gray-600 mb-4;
}
.theme-page-content pre {
  @apply p-3 bg-gray-200 block mb-3 overflow-auto max-w-full break-words whitespace-normal;
}

.theme-page-content table {
  @apply text-left w-full mb-4;
  border-collapse: collapse;
}

.theme-page-content tr {
  @apply border-b border-gray-400;
}

.theme-page-content tr:hover {
  @apply bg-gray-100;
}

.theme-page-content thead,
.theme-page-content th {
  @apply bg-gray-200 z-10;
}

.theme-page-content th {
  @apply py-2 px-4 bg-gray-200 font-semibold uppercase text-sm text-gray-600;
}
.theme-page-content td {
  @apply py-2 px-4;
}
.theme-page-content td,
.theme-page-content th,
.theme-page-content td p,
.theme-page-content th p {
  @apply mb-0 text-sm;
}

@media (max-width: 576px) {
  .theme-page-content table {
    position: sticky;
  }

  .theme-page-content table,
  .theme-page-content thead,
  .theme-page-content tbody,
  .theme-page-content th,
  .theme-page-content td,
  .theme-page-content tr {
    display: block;
  }

  .theme-page-content thead {
    position: sticky;
    top: -1px;
  }

  .theme-page-content thead th {
    white-space: nowrap;
  }

  .theme-page-content tr {
    border: 1px solid #ccc;
  }

  .theme-page-content tbody th,
  .theme-page-content td {
    padding-left: 36px;
    min-height: 38px;
  }

  .theme-page-content td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
  }

  .theme-page-content tbody th:before,
  .theme-page-content td:before {
    @apply px-4 font-bold absolute left-0;
  }

  .theme-page-content td:nth-child(1):before,
  .theme-page-content th:nth-child(1):before {
    content: '1. ';
  }
  .theme-page-content td:nth-child(2):before,
  .theme-page-content th:nth-child(2):before {
    content: '2. ';
  }
  .theme-page-content td:nth-child(3):before,
  .theme-page-content th:nth-child(3):before {
    content: '3. ';
  }
  .theme-page-content td:nth-child(4):before,
  .theme-page-content th:nth-child(4):before {
    content: '4. ';
  }
  .theme-page-content td:nth-child(5):before,
  .theme-page-content th:nth-child(5):before {
    content: '5. ';
  }
  .theme-page-content td:nth-child(6):before,
  .theme-page-content th:nth-child(6):before {
    content: '6. ';
  }
  .theme-page-content td:nth-child(7):before,
  .theme-page-content th:nth-child(7):before {
    content: '7. ';
  }
  .theme-page-content td:nth-child(8):before,
  .theme-page-content th:nth-child(8):before {
    content: '8. ';
  }
  .theme-page-content td:nth-child(9):before,
  .theme-page-content th:nth-child(9):before {
    content: '9. ';
  }
  .theme-page-content td:nth-child(10):before,
  .theme-page-content th:nth-child(10):before {
    content: '10 ';
  }
}
</style>
