<template>
  <div
    @click="$emit('open-nav-drop')"
    @mouseenter="$emit('open-nav-drop')"
  >
    <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" -->
    <button
      type="button"
      class="text-gray-500 group inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:text-gray-900 hover:underline focus:underline transition ease-in-out duration-150"
    >
      <span class="sub-link">{{ item.label }}</span>
      <!-- Item active: "text-gray-600", Item inactive: "text-gray-400" -->
      <svg
        :class="[{'rotate-180': isOpen}, 'transform text-gray-400 h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150']"
        aria-hidden="true"
        role="img"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <div
        v-show="isOpen"
        class="absolute mt-3 -mr-4 transform px-2 sm:px-0 right-0 z-20"
      >
        <div class="rounded-lg shadow-lg">
          <div class="rounded-lg shadow-xs overflow-hidden">
            <div class="relative flex flex-row bg-white px-4 py-4">
              <div v-for="(column, indexC) in columns" :key="indexC" class="flex flex-col space-y-2">
                <div v-for="(link, index) in column" :key="index" class="drop-item">
                  <template v-if="link.type === 'group'">
                    <NavSubGroup :list="link" />
                  </template>
                  <NavLink
                    v-else
                    :link="link"
                    class="-m-2 p-2 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    <div class="space-y-1">
                      <p class="text-base leading-6 item-link">
                        {{ link.label }}
                      </p>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import NavLink from './NavLink'
import NavSubGroup from './NavSubGroup'

export default {
  components: {
    NavLink,
    NavSubGroup,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    columns() {
      return this.item.children.reduce(
        (acc, curr, idx) => {
          const currentColumn = acc[acc.length - 1]
          if (
            currentColumn.length &&
            currentColumn[currentColumn.length - 1].type === 'group'
          ) {
            acc.push([curr])
          } else {
            currentColumn.push(curr)
          }
          return acc
        },
        [[]]
      )
    },
  },
}
</script>
<style scoped>
.drop-item {
  min-width: 200px;
}
.sub-link,
.sub-link:hover,
.sub-link:focus {
  color: var(--sidebarElementsFontColor, inherit);
  font-family: var(--sidebarElementsFontTypeface, inherit);
  font-weight: var(--sidebarElementsFontWeight, inherit);
}

.item-link {
  color: var(--sidebarElementsFontColor, var(--text-gray-700));
  font-family: var(--sidebarElementsFontTypeface, inherit);
  font-weight: var(--sidebarElementsFontWeight, 500);
}
</style>
